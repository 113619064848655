<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("createpromotion") }}
        </h3>
      </div>
      <!-- <div class="card-body">
        <div class="row text-sm-start d-flex justify-content-center">
          <div class="col-sm-10">
            <label for="validationTooltip01" class="form-label col-sm-10">{{
              $t("status")
            }}</label>
            <select class="form-select" v-model="form.status">
              <option value="active">ใช้งาน</option>
              <option value="inactive">ไม่ใช้งาน</option>
            </select>
          </div>

          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label">ชื่อหัวข้อ</label>
            <input v-model="form.code" class="form-control mb-4" />
          </div>
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label"
              >รหัสใช้คูปอง</label
            >
            <input v-model="form.name" class="form-control mb-4" />
          </div>
          <div class="col-sm-10">
            <label for="validationTooltip01" class="form-label col-sm-10"
              >ประเภทคูปอง</label
            >
            <select class="form-select" v-model="form.coupontype">
              <option value="totalbill">ลดทั้งบิล</option>
              <option value="category">ลดตามหมวดหมู่</option>
              <option value="brand">ลดตามยี่ห้อ</option>
              <option value="productonly">ลดเฉพาะสินค้า</option>
              <option value="shipping">ลดค่าจัดส่ง</option>
            </select>
          </div>

          <div v-if="form.coupontype === 'category'" class=" col-sm-10">
            <div >
              <label for="validationTooltip01" class="form-label "
                >ลดตามหมวดหมู่</label
              >
              <select class="form-select" v-model="form.หมวดหมู่">
                <option value="amount">หมวดA</option>
                <option value="percent">หมวดB</option>
              </select>
            </div>
          </div>

          <div v-if="form.coupontype === 'brand'" class=" col-sm-10">
            <div >
              <label for="validationTooltip01" class="form-label "
                >ลดตามยี่ห้อ</label
              >
              <select class="form-select" v-model="form.ลดตามยี่ห้อ">
                <option value="amount">แบรนA</option>
                <option value="percent">แบรนB</option>
              </select>
            </div>
          </div>

          <div v-if="form.coupontype === 'productonly'" class=" col-sm-10">
            <div>
              <label for="validationTooltip01" class="form-label "
                >ลดเฉพาะสินค้า</label
              >
              <select class="form-select" v-model="form.ลดเฉพาะสินค้า">
                <option value="amount">สินค้าA</option>
                <option value="percent">สินค้าB</option>
              </select>
            </div>
          </div>

          <div class="col-sm-10">
            <label for="validationTooltip01" class="form-label col-sm-10"
              >ประเภทการลด</label
            >
            <select class="form-select" v-model="form.discounttype">
              <option value="amount">จำนวนเงิน</option>
              <option value="percent">เปอร์เซ็นต์</option>
            </select>
          </div>
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label">ราคาส่วนลด</label>
            <input v-model="form.name" class="form-control mb-4" />
          </div>
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label"
              >ขั้นต่ำในการใช้</label
            >
            <input v-model="form.name" class="form-control mb-4" />
          </div>
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label">จำนวนคูปอง</label>
            <input v-model="form.name" class="form-control mb-4" />
          </div>
          <div class="row text-sm-start d-flex justify-content-center col-sm-10 ">
            <label for="selectMainProduct" class="form-label"
              >ระยะเวลาคูปอง</label
            >

            <div class="col-sm-6">
              <a-date-picker
                class="form-control ant-date mb-4"
                v-model:value="date"
                format="DD/MM/YYYY"
                style="border-radius: 7px"
              />
            </div>
            <div class="col-sm-6">
              <a-date-picker
                class="form-control ant-date mb-4"
                v-model:value="date"
                format="DD/MM/YYYY"
                style="border-radius: 7px"
              />
            </div>
          </div>
        </div>
      </div> -->
      <div class="card-body">
        <div class="row text-sm-start d-flex justify-content-center">
          <div class="col-sm-10">
            <label
              for="validationTooltip01"
              class="form-label col-sm-10 required"
              >ชื่อโปรโมชัน</label
            >
            <!-- <select class="form-select" v-model="form.status">
              <option value="active">ใช้งาน</option>
              <option value="inactive">ไม่ใช้งาน</option>
            </select> -->
            <input
              type="text"
              class="form-control"
              v-model="form.promotion_name"
            />
          </div>
          <div class="col-sm-10">
            <label
              for="validationTooltip01"
              class="form-label col-sm-10 required"
              >โปรโมชันสำหรับลูกค้าเก่า</label
            >
            <select class="form-select" v-model="form.isNewCustomer">
              <option :value="false">ลูกค้าเก่า</option>
              <option :value="true">ลูกค้าใหม่</option>
            </select>
          </div>
          <div class="col-sm-10">
            <label
              for="validationTooltip01"
              class="form-label col-sm-10 required"
              >ประเภทโปรโมชัน</label
            >
            <select
              class="form-select"
              v-model="form.promotion_type"
              placeholder="เลือกเงื่อนไขโปรโมชัน"
            >
              <option value="ซื้อ a แถม b">ซื้อ a แถม b</option>
              <option value="ซื้อครบ a แถม b">ซื้อครบ a แถม b</option>
              <option value="ซื้อครบ a แถม กลุ่มสินค้า b">
                ซื้อครบ a แถม กลุ่มสินค้า b
              </option>
              <option value="ซื้อครบ a ลด b">ซื้อครบ a ลด b</option>
            </select>
          </div>
          <div
            class="row text-sm-start d-flex justify-content-center col-sm-10"
          >
            <label for="selectMainProduct" class="form-label"
              >ระยะเวลาโปรโมชัน</label
            >

            <div class="col-sm-6">
              <a-date-picker
                class="form-control ant-date mb-4"
                v-model:value="form.start_date"
                format="DD/MM/YYYY"
                style="border-radius: 7px"
              />
            </div>
            <div class="col-sm-6">
              <a-date-picker
                class="form-control ant-date mb-4"
                v-model:value="form.end_date"
                format="DD/MM/YYYY"
                style="border-radius: 7px"
              />
            </div>
          </div>
          <div
            v-if="form.promotion_type == 'ซื้อ a แถม b'"
            class="row text-sm-start d-flex justify-content-center"
          >
            <div class="col-sm-10">
              <label
                for="validationTooltip01"
                class="form-label col-sm-10 required"
                >รายการซื้อ</label
              >
              <div class="text-end col-sm-12">
                <button
                  type="button"
                  class="btn btn-sm btn-light btn-success"
                  style="font-size: 14px; font-weight: bold"
                  @click="openDialogSearchBuyProduct()"
                >
                  {{ $t("addon") }}
                </button>
                <DialogSearchBuyProduct
                  :dialogSearchBuyProduct="dialogSearchBuyProduct"
                  @closeDialogSearchBuyProduct="closeDialogSearchBuyProduct"
                  @submitSearchBuyProduct="submitSearchBuyProduct"
                  :warehouseId="form.warehouseId"
                />
              </div>
              <a-table :dataSource="dataItemsBuyShow" :columns="columns">
                <template #bodyCell="{ column, index, record }">
                  <template v-if="column.dataIndex === 'no'">
                    {{ record.no }}
                  </template>
                  <template v-if="column.dataIndex === 'code'">
                    {{ record.productCode }}
                  </template>
                  <template v-if="column.dataIndex === 'name'">
                    {{ record.productName }}
                  </template>
                  <template v-if="column.dataIndex === 'item_amt'">
                    <input
                      type="text"
                      class="form-control"
                      v-model="record.item_amt"
                    />
                  </template>
                  <template v-if="column.dataIndex === 'unitName'">
                    {{ record.unitName }}
                  </template>
                  <template v-if="column.dataIndex === 'action'">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger m-0 p-0"
                      @click="goToDeletedataItemsBuyShow(record, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </template>
                </template>
              </a-table>
            </div>
            <div class="col-sm-10">
              <label
                for="validationTooltip01"
                class="form-label col-sm-10 required"
                >รายการแถม</label
              >

              <div class="text-end col-sm-12">
                <button
                  type="button"
                  class="btn btn-sm btn-light btn-success"
                  style="font-size: 14px; font-weight: bold"
                  @click="openDialogSearchProcuct()"
                >
                  {{ $t("addon") }}
                </button>
                <DialogSearchProduct
                  :dialogSearchProduct="dialogSearchProduct"
                  @closeDialogSearchProduct="closeDialogSearchProduct"
                  @submitSearchProduct="submitSearchProduct"
                  :warehouseId="form.warehouseId"
                />
              </div>
              <!-- //// -->
              <a-table :dataSource="dataItemsShow" :columns="columns">
                <template #bodyCell="{ column, index, record }">
                  <template v-if="column.dataIndex === 'no'">
                    {{ record.no }}
                  </template>
                  <template v-if="column.dataIndex === 'code'">
                    {{ record.productCode }}
                  </template>
                  <template v-if="column.dataIndex === 'name'">
                    {{ record.productName }}
                  </template>
                  <template v-if="column.dataIndex === 'item_amt'">
                    <input
                      type="text"
                      class="form-control"
                      v-model="record.item_amt"
                    />
                  </template>
                  <template v-if="column.dataIndex === 'unitName'">
                    {{ record.unitName }}
                  </template>
                  <template v-if="column.dataIndex === 'action'">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger m-0 p-0"
                      @click="goToDeletedataItemsShow(record, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
          <div
            v-if="form.promotion_type == 'ซื้อครบ a แถม b'"
            class="row text-sm-start d-flex justify-content-center"
          >
            <div class="col-sm-10">
              <label
                for="validationTooltip01"
                class="form-label col-sm-10 required"
                >ซื้อครบ ## บาท</label
              >
              <input
                type="text"
                class="form-control"
                v-model="form.minimum_price"
              />
            </div>
            <div class="col-sm-10">
              <label
                for="validationTooltip01"
                class="form-label col-sm-10 required"
                >รายการแถม</label
              >

              <div class="text-end col-sm-12">
                <button
                  type="button"
                  class="btn btn-sm btn-light btn-success"
                  style="font-size: 14px; font-weight: bold"
                  @click="openDialogSearchProcuct()"
                >
                  {{ $t("addon") }}
                </button>
                <DialogSearchProduct
                  :dialogSearchProduct="dialogSearchProduct"
                  @closeDialogSearchProduct="closeDialogSearchProduct"
                  @submitSearchProduct="submitSearchProduct"
                  :warehouseId="form.warehouseId"
                />
              </div>
              <!-- //// -->
              <a-table :dataSource="dataItemsShow" :columns="columns">
                <template #bodyCell="{ column, index, record }">
                  <template v-if="column.dataIndex === 'no'">
                    {{ record.no }}
                  </template>
                  <template v-if="column.dataIndex === 'code'">
                    {{ record.productCode }}
                  </template>
                  <template v-if="column.dataIndex === 'name'">
                    {{ record.productName }}
                  </template>
                  <template v-if="column.dataIndex === 'item_amt'">
                    <input
                      type="text"
                      class="form-control"
                      v-model="record.item_amt"
                    />
                  </template>
                  <template v-if="column.dataIndex === 'unitName'">
                    {{ record.unitName }}
                  </template>
                  <template v-if="column.dataIndex === 'action'">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger m-0 p-0"
                      @click="goToDeletedataItemsShow(record, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
          <div
            v-if="form.promotion_type == 'ซื้อครบ a แถม กลุ่มสินค้า b'"
            class="row text-sm-start d-flex justify-content-center"
          >
            <div class="col-sm-10">
              <label
                for="validationTooltip01"
                class="form-label col-sm-10 required"
                >ซื้อครบ ## บาท</label
              >
              <input
                type="text"
                class="form-control"
                v-model="form.minimum_price"
              />
            </div>
            <div class="col-sm-5">
              <label
                for="validationTooltip01"
                class="form-label col-sm-10 required"
                >กลุ่มสินค้า</label
              >

              <div class="text-end col-sm-12"></div>
              <select class="form-select" v-model="form.itemGroupHeaderId">
                <option :value="item.id" v-for="item in itemGroups" :key="item">
                  {{ item.code }} | {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-sm-5">
              <label
                for="validationTooltip01"
                class="form-label col-sm-10 required"
                >จำนวน</label
              >

              <div class="text-end col-sm-12"></div>
              <input type="text" class="form-control" v-model="form.item_amt" />
            </div>
          </div>

          <!-- <div
            v-if="form.promotion_type == 'ซื้อครบ a ลด b'"
            class="row text-sm-start d-flex justify-content-center"
          >
            <div class="col-sm-5">
              <label for="validationTooltip01" class="form-label col-sm-10"
                >ซื้อครบ ## บาท</label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="col-sm-5">
              <label for="validationTooltip01" class="form-label col-sm-10"
                >ลด ## บาท</label
              >
              <input type="text" class="form-control" />
            </div>
          </div> -->
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar">
          <button
            type="submit"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submit()"
          >
            {{ $t("save") }}
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </footer>
    </form>
  </div>
</template>
<script>
import promotionAPI from "@/api/promotion/";
import whApi from "@/api/warehouse/";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DialogSearchProduct from "./components/dialogSearchProduct.vue";
import DialogSearchBuyProduct from "./components/dialogSearchBuyProduct.vue";

export default {
  components: {
    DialogSearchProduct,
    DialogSearchBuyProduct,
  },
  data: () => ({
    form: {
      code: "",
      name: "",
      warehouseId: localStorage.getItem("warehouseId"),
    },
    dialogSearchProduct: false,
    dialogSearchBuyProduct: false,
    dataItemsShow: [],
    dataItemsBuyShow: [],
    columns: [
      { title: "no", dataIndex: "no" },
      // { title: "productId", dataIndex: "productId" },
      { title: "productCode", dataIndex: "productCode" },
      { title: "productName", dataIndex: "productName" },
      { title: "item_amt", dataIndex: "item_amt" },
      { title: "unitName", dataIndex: "unitName" },
      { title: "action", dataIndex: "action" },
    ],
    promotionItems: [],
    itemGroups: [],
    // valid: new Date(),
  }),
  created() {
    this.getMainProduct();
  },
  methods: {
    goToDeletedataItemsBuyShow(record, index) {
      this.dataItemsBuyShow.splice(index, 1);
    },
    goToDeletedataItemsShow(record, index) {
      this.dataItemsShow.splice(index, 1);
    },
    async getMainProduct() {
      let responseData = [];
      try {
        responseData = await whApi.itemGroup.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.itemGroups = responseData.data;
      }
    },
    submitSearchProduct(item) {
      console.log("submitSearchProduct", item);
      this.dataItemsShow.push(item);
    },
    openDialogSearchProcuct() {
      this.dialogSearchProduct = true;
    },
    closeDialogSearchProduct() {
      this.dialogSearchProduct = false;
    },
    submitSearchBuyProduct(item) {
      console.log("submitSearchBuyProduct", item);
      this.dataItemsBuyShow.push(item);
    },
    openDialogSearchBuyProduct() {
      this.dialogSearchBuyProduct = true;
    },
    closeDialogSearchBuyProduct() {
      this.dialogSearchBuyProduct = false;
    },
    async submit() {
      let createResponse = [];

      this.loading = true;

      this.form = {
        ...this.form,
        companyId: parseInt(localStorage.getItem("companyId")),
        promotionItems: this.dataItemsShow,
      };

      createResponse = await promotionAPI.promotion.create(this.form);
      if (createResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "เพิ่มสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.$router.push("/promotion/promotion/index");
        });
        this.loading = false;
      } else {
        Swal.fire({
          icon: "error",
          title: "เพิ่มไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loading = false;
      }
    },
    cancel() {
      this.$router.push("/promotion/promotion/index");
    },
  },
};
</script>